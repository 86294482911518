import {
  POPUP_STAGE_HIDDEN,
  POPUP_STAGE_LOADING,
  POPUP_STAGE_SUCCESS,
  POPUP_STAGE_CONSENT,
  POPUP_STAGE_ERROR,
  POPUP_STAGE_LOCK,
} from '../actions/types'

const INIT_STATE = {
  display: 'hidden',
  msg: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case POPUP_STAGE_HIDDEN: {
      if (state.display === 'success') {
        window.location.reload()
      }
      document.body.classList.remove('modal-open')
      return {
        ...state,
        display: 'hidden',
      }
    }
    case POPUP_STAGE_LOADING: {
      document.body.classList.add('modal-open')
      return {
        ...state,
        display: 'loading',
      }
    }
    case POPUP_STAGE_SUCCESS: {
      document.body.classList.add('modal-open')
      return {
        ...state,
        display: 'success',
      }
    }
    case POPUP_STAGE_CONSENT: {
      document.body.classList.add('modal-open')
      return {
        ...state,
        display: 'consent',
      }
    }
    case POPUP_STAGE_ERROR: {
      document.body.classList.add('modal-open')
      return {
        ...state,
        display: 'error',
        msg: action.payload.msg,
      }
    }
    case POPUP_STAGE_LOCK: {
      document.body.classList.add('modal-open')
      return {
        ...state,
        display: 'lock',
      }
    }
    default:
      return state
  }
}
