import axios from 'axios'
import moment from 'moment'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { SetCodeValid, popupError, popupSuccess, setTicketData } from '../actions'
import { CODE_GET_VALID, CODE_POST_MONDAY, CODE_POST_REDEEM_CORE } from '../actions/types'
var mondaySchema = {
  date4: {
    date: '',
  },
  phone4: '',
  __________1: '',
  corporate8: {
    labels: ["MUANGTHAI"],
  },
  corporate: {
    label: "งานใหม่",
  },
  email4: '',
  dropdown0: {
    labels: ["THAILAND"],
  },
  status92: {},
  ______________: '',
  text27: '',
  status29: {
    label: "งานส่งออกไปสนามบิน",
  },
  status4: {
    label: "ROBOT-MuangThai",
  },
  date5: {
    date: '',
  },
  status6: {
    label: "System",
  },
  long_text5: { text: '' },
  long_text0: { text: '' },
  long_text54: { text: '' },
}

var booking_form = [
  'fullname',
  'firstname',
  'lastname',
  'mobile',
  'email',
  'booking_date',
  'booking_time',
  'person',
  'luggage',
  'pick_up_type',
  'pick_up_address',
  'pick_up_landmark',
  'pick_up_airport',
  'pick_up_flight_number',
  'drop_off_type',
  'drop_off_address',
  'drop_off_landmark',
  'drop_off_airport',
  'drop_off_flight_number',
  'note',
  'car_type'
]

const addField = (data) => {
  let tempData = {}
  booking_form.map((field, index) => {
    // let object = {}
    // object["field_name"]  =  field
    // object["field_value"]  = data[index]
    tempData[field] = data[index]
    // tempData.push(object)
  })

  debugger

  return tempData
  //{"field_name":"address","field_value":"Central World2"}
}
const postToMondayRequest = async (payload) => {
  //console.log(res)('payload', payload)
  const statusJob = () => {
    if (payload.data.pickupType === '1' && payload.data.dropoffType === '1') return 15
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '1') return 2
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '2') return 2
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '1') return 13
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '2') return 1
    return 5
  }

  const cartype = () => {
    if (payload.data.car_type === 'BMW series 5') return 16
    else if (payload.data.car_type === 'ALPHARD') return 14
    else if (payload.data.car_type === 'H1') return 10
    else if (payload.data.car_type === 'CAMRY') return 2
    else if (payload.data.car_type === 'BENZ E CLASS') return 1
    return 5
  }

  mondaySchema.date4.date = moment(payload.data.Datetime).format('YYYY-MM-DD')
  mondaySchema.phone4 = payload.data.phone
  mondaySchema.__________1 = payload.data.bookingTime
  mondaySchema.email4 = payload.data.email
  mondaySchema.status92.index = cartype()
  mondaySchema.______________ = payload.data.person + '/' + payload.data.luggage
  mondaySchema.text27 = payload.data.pick_upFlight || payload.data.drop_offFlight
  mondaySchema.status29.index = statusJob()
  mondaySchema.long_text5.text =
    payload.data.pickupType === '1'
      ? payload.data.pick_upAddress + payload.data.pick_up_interest
      : payload.data.pick_up_airport === '1'
      ? 'SUVARNABHUMI Airport'
      : 'DON MUANG Airport'

  mondaySchema.long_text0.text =
    payload.data.dropoffType === '1'
      ? payload.data.drop_offAddress + payload.data.drop_off_interest
      : payload.data.drop_off_airport === '1'
      ? 'SUVARNABHUMI Airport'
      : 'DON MUANG Airport'
  mondaySchema.text0 = ''
  mondaySchema.long_text54.text = payload.data.note 
  if (payload.data.coupon.validationCodeFromServer.coupon_status === 'active') {
    mondaySchema.long_text54.text +=
      'รหัสนี้ได้ใช้งานไปแล้วในรายการการจองที่ ' + payload.data.coupon.validationCodeFromServer.booking_id
    mondaySchema.text0 += '(รหัสนี้ได้ใช้งานไปแล้ว ตรวจสอบและติดต่อกลับหาลูกค้า) '
  }
  mondaySchema.text0 += payload.code

  mondaySchema.date5.date = moment(payload.data.startDate).format('YYYY-MM-DD')
  ///
  var ColumnValue = JSON.stringify(mondaySchema)

  const body = {
    query: `mutation ($board: Int!, $name: String!,  $group: String!, $column: JSON!) { 
		create_item( board_id: $board, item_name: $name,group_id :$group , column_values:$column) { 
		  id 
		  column_values { 
			id 
			value 
		  } 
		} 
	  } `,
    variables: {
      board: 189387779,
      name: payload.data.firstname + ' ' + payload.data.lastname,
      group: '________',
      // group: 'new_group25',
      column: ColumnValue,
    },
  }
  return await axios
    .post(process.env.REACT_APP_MONDAY_URL, body, {
      headers: {
        // Authorization:
        //   'Bearer '+ process.env.REACT_APP_MONDAY_TOKEN,
      },
    })
    .catch((err) => {
      console.error(err.data)
    })
    .then((res) => {
      ////console.log(res)(res.data)
      return res.data
    })
}

const getCodeValidRequest = async (payload) => {
  let url = process.env.REACT_APP_API_URL + '/v1/coupons/' + payload.CouponID + '/code/' + payload.Code

  return await axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
      },
    })
    .catch((err) => {
      console.error(err.error)
    })
    .then((res) => {
      ////console.log(res)(res)
      return res
    })
}

const postToCoreRequest = async (payload) => {
  //console.log(res)('payload', payload)
  let url = process.env.REACT_APP_API_URL + '/v1/redemptions'

  return await axios
    .post(
      url,
      {
        privilege_id: payload.privilege_id,
        ref_1: payload.data.firstname,
        ref_2: payload.ticketID,
        coupons: [
          {
            coupon_id: payload.coupon_id,
            coupon_code: payload.code,
          },
        ],
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
        },
      }
    )
    .catch((err) => {
      return err.response.data.error;
    })
    .then((res) => {
      //console.log(res)(res)
      return res
    })
}

const putBookingToCoreRequest = async (payload) => {
  //console.log(res)('payload_Booking', payload)
  //TODO : add field
  let url = process.env.REACT_APP_API_URL + '/v1/bookings/' + payload.booking_id

  return await axios
    .put(
      url,
      {
        booking_status: 'book',
        input_data: payload.field,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accesstokens'),
        },
      }
    )
    .catch((err) => {
      console.error(err.error)
    })
    .then((res) => {
      //console.log(res)(res)
      return res
    })
}

function* postToMonday({ payload }) {
  try {
    const data = yield call(postToMondayRequest, payload)
    if (data) {
      yield put(popupSuccess())
      yield put(setTicketData(data.data.create_item.id))
    } else {
      throw new Error(data.err_code)
    }
  } catch (error) {
    yield put(popupError('การส่งข้อมูลไม่สำเร็จ'))
  }
}

function* codeValidation({ payload }) {
  try {
    const data = yield call(getCodeValidRequest, payload)
    //console.log(res)(data)
    if (data) {

      const now = moment();
      const endDate = moment(data.data.end_date);
      const diff = endDate.diff(now)

      yield put(SetCodeValid(data.data))
      if (data.data.coupon_status === 'active' || data.data.coupon_status === 'used') {
        yield put(popupError('รหัส ' + data.data.coupon_code + ' นี้ถูกใช้แล้ว กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'))
      } else if (data.data.coupon_status === 'expired' || diff < 0) {
        yield put(popupError('ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสหมดอายุการใช้งาน'))
      } else if (data.data.coupon_status === 'cancel') {
        yield put(popupError('ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสถูกยกเลิกการใช้งาน'))
      }
    } else {
      throw new Error(data)
    }
  } catch (error) {
    yield put(popupError('กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง หรือติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'))

    //SET ERROR
  }
}

function* postRedeemToCore({ payload }) {
  // try {
    // const data = yield call(postToMondayRequest, payload)
    // if (data) {
      // if (data.data.create_item.id) {
        // payload.ticketID = data.data.create_item.id

        const dataRCore = yield call(postToCoreRequest, payload)
        if (dataRCore && dataRCore.statusCode!==400) {
          payload.booking_id = dataRCore.data.bookings[0].id

          yield put(setTicketData(dataRCore.data.bookings[0].id))
          let valueArr = [
            payload.data.firstname + ' ' + payload.data.lastname,
            payload.data.firstname,
            payload.data.lastname,
            payload.data.phone,
            payload.data.email,
            moment(payload.data.Datetime).format('YYYY-MM-DD'),
            payload.data.bookingTime,
            payload.data.person,
            payload.data.luggage,
            payload.data.pickupType,
            payload.data.pick_upAddress,
            payload.data.pick_up_interest,
            payload.data.pick_up_airport,
            payload.data.pick_upFlight,
            payload.data.dropoffType,
            payload.data.drop_offAddress,
            payload.data.drop_off_interest,
            payload.data.drop_off_airport,
            payload.data.drop_offFlight,
            payload.data.note,
            payload.data.car_type
          ]

          payload.field = addField(valueArr)

          //console.log(res)(addField(valueArr))
          const dataBooking = yield call(putBookingToCoreRequest, payload)
          if (dataBooking) {
            yield put(popupSuccess())
          }
        // }
      // }
    } else {
      yield put(popupError(dataRCore.message=="Code is not available"?"รหัสถูกใช้งานไปแล้ว":'การส่งข้อมูลไม่สำเร็จ'))
      // throw new Error(dataRCore.err_code)
    }
  // } catch (error) {
  //   debugger
  //   yield put(popupError('การส่งข้อมูลไม่สำเร็จ'))
  // }
}

function* requestCodeWatcher() {
  yield takeEvery(CODE_POST_MONDAY, postToMonday)
}

function* validCodeWatcher() {
  yield takeEvery(CODE_GET_VALID, codeValidation)
}

function* requestRedeemCoreWatcher() {
  yield takeEvery(CODE_POST_REDEEM_CORE, postRedeemToCore)
}

export default function* rootSaga() {
  yield all([fork(requestCodeWatcher), fork(validCodeWatcher), fork(requestRedeemCoreWatcher)])
}
