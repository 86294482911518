import { CODE_SET_DATA, CODE_GET_VALID, CODE_SET_VALID } from '../actions/types'

const INIT_STATE = {
  ticketId: 0,
  car_pic: 0,
  validationCodeFromServer: '',
  booking: {},
  car_type: {
    SVCAMRYTEST: { privilege_id: 17, coupon_id: 16, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    SVHYUNDAITEST: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    SVHYUNDAI: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    SVCAMRY: { privilege_id: 17, coupon_id: 16, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    SVBENBMWTEST: {
      privilege_id: 19,
      coupon_id: 18,
      value: 'BENBMW',
      car: ['BENZ E CLASS', 'BMW series 5'],
      picIndex: 2,
    },
    
    SVBENBMW: { privilege_id: 19, coupon_id: 18, value: 'BENBMW', car: ['BENZ E CLASS', 'BMW series 5'], picIndex: 2 },
    SVALPHARD: { privilege_id: 20, coupon_id: 19, value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4 },
    SVALPHARDTEST: { privilege_id: 20, coupon_id: 19, value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4 },
    CAMRY: { privilege_id: 17, coupon_id: 16, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    ALPHARD: { privilege_id: 20, coupon_id: 19, value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4 },
    // HYUNDAI: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    HYUNDA: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    MTL: { privilege_id: 17, coupon_id: 16, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    MTLCAM: { privilege_id: 17, coupon_id: 16, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    SMC: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    SMCH: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
    SMCHYU: { privilege_id: 18, coupon_id: 17, value: 'H1', car: ['H1'], picIndex: 0 },
  },
  mondayModel: {
    name: '',
    text: '',
  },
  carMaxDetail: {
    H1: {
      pax: '5 (28")',
      person: 5,
      luggage: 5,
      Msg: 'Hyundai H1',
    },
    CAMRY: {
      pax: '2 (28")',
      person: 2,
      luggage: 2,
      Msg: 'Toyota Camry',
    },
    BENBMW: {
      pax: '3 (26") / 2 (28")',
      person: 3,
      luggage: 2,
      Msg: '',
    },
    ALPHARD: {
      pax: '4 (28")',
      person: 4,
      luggage: 4,
      Msg: 'Toyota Alphard',
    },
  },
  coupon: {},
  coperate: {},
  branch: {},
  coupon_id: 0,
  coupon_code: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CODE_SET_DATA: {
      return {
        ...state,
        ticketId: action.payload.id,
      }
    }
    case CODE_SET_VALID: {
      return {
        ...state,
        validationCodeFromServer: action.payload.data,
      }
    }

    default:
      return state
  }
}
