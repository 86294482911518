import React, { Component } from 'react'

import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

class SideMenu extends Component {
  render() {
    return (
      <div className="desktop-side-bg nav-main d-none d-md-block col-md-7 p-3 text-center">
        <a className="py-3 mr-3 d-inline-block " href="#section">
          Home
        </a>
        <br />
        <a className="py-3 mr-3 d-inline-block " href="#section">
          <span>Limousine</span> Service
        </a>
        <br />
      </div>
    )
  }
}

export default SideMenu
