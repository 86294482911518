import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import styles from '../../assets/css/form-stylesheet.css' // Import css modules stylesheet as styles
import { popupLoading, submitBookingMonday, submitToCore, popupError, GetCodeValid } from '../../actions'
import { useTranslation, withTranslation, Translation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import smileClub from '../../assets/imgs/smileclub.jpg'
import alpardCar from '../../assets/imgs/home/alphard-.png'
import allCar from '../../assets/imgs/home/5car.png'
import camryCar from '../../assets/imgs/camry-01.png'
import benzCar from '../../assets/imgs/benz.png'
import bmwCar from '../../assets/imgs/bmw-01.png'
import h1Car from '../../assets/imgs/hyundai-01.png'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { max } from 'moment'

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

class MainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      bookingTime: '',
      person: '',
      luggage: '',
      car_type: '',
      car_pic: 5,
      carArr: [],
      pick_upAddress: '',
      Message: '',
      pick_up_interest: '',
      pick_upFlight: '',
      pick_up_airport: '1',
      drop_offAddress: '',
      drop_off_interest: '',
      drop_off_airport: '1',
      drop_offFlight: '',
      maxPAX: '',
      note: '',
      remark: '',
      maxPerson: '',
      flagValidCar: false,
      data: this.props.data,
      coupon: this.props.coupon,
      code: '',
      selectedOption: null,
      pickupType: 0,
      dropoffType: 0,
      startDate: new Date(),
      Datetime: '',
      privilege_id: '',
      coupon_id: '',
      disabled: false,
      readOnly: false,
      maxPersons: 1,
      maxLuggage: 1,
    }
    this.onchangeDate = this.onchangeDate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkCode = this.checkCode.bind(this)
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #f0027f',
    color: '000000',
  }
  codeStyle = {
    borderRadius: '0px 20px 20px 0px',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #f0027f',
    color: '000000',
  }

  async componentDidMount() {
    // if (localStorage.getItem('accesstokens')) {
    //   axios
    //     .post(process.env.REACT_APP_API_URL  + process.env.REACT_APP_API_REFRESH ,{} ,{
    //       headers: {
    //         Authorization:
    //             'Bearer '+ localStorage.getItem("accesstokens")
    //       },
    //     })
    //     .then(res => {
    //       localStorage.setItem('accesstokens', res.data.access_token)
    //       localStorage.setItem('expires_in', res.data.expires_in)
    //     })
    //     .catch (error =>{
    //       ////console.log(res)(error)
    //       localStorage.removeItem('accesstokens')
    //       this.props.popupError('ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง โทรศัพท์ : 02-0169950  ขออภัยในความไม่สะดวกอย่างสูง')
    //     } )
    // }
    // else  {
    await axios
      .post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_OAUTH, {
        client_id: process.env.REACT_APP_MT_CLIENT_ID,
        channel_secret: process.env.REACT_APP_MT_KEYSEC_ID,
      })
      .then((res) => {
        localStorage.setItem('accesstokens', res.data.access_token)
        localStorage.setItem('expires_in', res.data.expires_in)
      })
      .catch((error) => {
        this.props.popupError(
          'ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง โทรศัพท์ : 02-0169950  ขออภัยในความไม่สะดวกอย่างสูง'
        )
      })
    // }
  }

  onchangeDate(date) {
    this.setState({
      Datetime: date,
    })
  }

  handleInputChange(value, name) {
    // Booking Time Formatter
    try {
      this.setState({ [name]: value })
      let val = ''
      if (name == 'bookingTime') {
        val = value + ''
        let numberCode = val.match('[0-9]+')
        if (val.length === 2 && numberCode[0].length === 2) {
          val += ':'
        }

        this.setState({ [name]: val.substring(0, 5) })
      }
    } catch (e) {
      ////console.log(res)(e)
    }
  }

  returnMsgCar(car_type, lng, type) {
    // Hard code Message
    if (car_type == '') return ''
    if (car_type == 'BENZ E CLASS' || car_type == 'BMW series 5') car_type = 'BENBMW'
    let carMsg = this.state.data[1][car_type].Msg
    let Msg = carMsg != '' ? carMsg : car_type
    if (lng == 'en')
      return (
        Msg +
        ' Max ' +
        this.state.data[1][car_type].person +
        ' person and ' +
        this.state.data[1][car_type].pax +
        ' luggage'
      )
    if (lng == 'th')
      return (
        Msg +
        ' สูงสุด ' +
        this.state.data[1][car_type].person +
        ' ท่านและกระเป๋าสูงสุด ' +
        this.state.data[1][car_type].pax +
        ' ใบ'
      )
    if (type === 'p') return this.state.data[1][car_type].person
    if (type === 'l') return this.state.data[1][car_type].pax
  }

  onChangeLimousineCode(value) {
    // Code valid
    //In next version : Have to remove hardcode privilege data
    try {
      let strValue = String(value)
      let prefixCode = strValue.match('[A-Z]+')
      let numberCode = strValue.match('[0-9]+')
      let numberCodeLeght = 0
      let car_type = ''
      let carArrs = []

      this.setState({ car_pic: 5 })
      this.setState({ code: strValue.toUpperCase() })
    } catch (e) {
      ////console.log(res)(e)
    }
  }

  checkCode() {
    try {
      let strValue = String(this.state.code)
      let prefixCode = strValue.match('^[A-Z]+')
      let numberCode = strValue.match('[0-9]+')
      let numberCodeLeght = 0
      let car_type = ''
      let carArrs = []

      this.setState({ code: strValue.toUpperCase() })
      if (this.state.data[2][prefixCode] && numberCode) {
        car_type = this.state.data[2][prefixCode].value //Get value from store (data)
        carArrs = this.state.data[2][prefixCode].car

        numberCodeLeght = numberCode[0].length
        if (car_type === 'BENBMW') car_type = 'BENZ E CLASS'

        this.setState({
          car_pic: this.state.data[2][prefixCode].picIndex,
          privilege_id: this.state.data[2][prefixCode].privilege_id,
          coupon_id: this.state.data[2][prefixCode].coupon_id,
        })
      }else{
        this.props.popupError('รูปแบบโค้ดไม่ถูกต้อง')
      }

      let booleanValid = (this.state.data[2][prefixCode] && numberCode) !== undefined && numberCodeLeght >= 3

      this.setState({
        car_type: car_type,
        carArr: carArrs,
        flagValidCar: booleanValid,
      })

      if (booleanValid) {
        const selectedCar = this.state.data[1][this.state.data[2][prefixCode].value]

        if(selectedCar){
          this.setState({
            maxLuggage: selectedCar.luggage,
            maxPersons: selectedCar.person
          })
        }

        this.props.GetCodeValid(this.state.data[2][prefixCode].coupon_id, strValue.toUpperCase())
      } else {
        this.props.popupError('รูปแบบโค้ดไม่ถูกต้อง')
      }
    } catch (e) {}
  }

  handleSubmit(e) {
    e.preventDefault()

    let validationCodeFromServer = this.props.coupon.validationCodeFromServer
    if (!this.state.flagValidCar) {
      this.props.popupError('กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง')
    } else if (validationCodeFromServer === '') {
      this.props.popupError('กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง')
    } else if (validationCodeFromServer.coupon_status === 'active') {
      // pin into note ว่าใช้แล้ว
      this.props.popupError(
        'รหัส ' + this.state.code + ' นี้ถูกใช้แล้ว กรุณาติดต่อ 02-0169950 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
      //this.props.popupLoading()
      // this.props.submitBookingMonday(this.state.code, this.state)
    } else if (validationCodeFromServer.coupon_status === 'expired') {
      this.props.popupError('ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสหมดอายุการใช้งาน')
    } else if (validationCodeFromServer.coupon_status === 'cancel') {
      this.props.popupError('ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสถูกยกเลิกการใช้งาน')
    } else if (validationCodeFromServer.coupon_status === 'available') {
      this.props.popupLoading()
      this.setState({ disabled: true })
      this.props.submitToCore(this.state.code, this.state, this.state.privilege_id, this.state.coupon_id)
    }
  }
  componentWillReceiveProps(newProps) {
    if(newProps.coupon){

      if(newProps.coupon.validationCodeFromServer.coupon_status==="available"){
        this.setState({
          readOnly: true
        })
        this.setState({ coupon: newProps.coupon })
      }
    }
  }

  render() {
    const car_pic = this.state.car_pic
    const carPicArr = [h1Car, camryCar, benzCar, bmwCar, alpardCar, allCar]

    return (
      <div style={{ maxWidth: '340px', margin: 'auto' }}>
        <img src={smileClub} alt="SmileClub" style={{ width: '300px', margintop: '-50px' }} />
        <h1 className="pt-2">
          {' '}
          <img src={carPicArr[car_pic]} alt="LIMOUSINE" style={{ width: '230px' }} />{' '}
        </h1>
        <h2 className="text-head">
          <span>Limousine</span> Service
        </h2>
        <form onSubmit={this.handleSubmit}>
          <div id="step-1" className="step pt-2">
            <div className="row pt-2">
              <div className="form-group col">
                <div className="input-group">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="code-box"
                        readOnly={this.state.readOnly}
                        style={{ borderRadius: '0px 20px 20px 0px' }}
                        name="code"
                        className="form-control "
                        placeholder={t('code')}
                        onChange={(e) => this.onChangeLimousineCode(e.target.value)}
                        value={this.state.code}
                      />
                    )}
                  </Translation>
                  <button
                    type="button"
                    id="btn-submit"
                    style={{ width: '35%' }}
                    className="btn-red-gradient"
                    onClick={() => {
                      if(this.state.readOnly){
                        this.setState({
                          readOnly: false
                        })
                      }else{
                        this.checkCode()
                      }
                    }}
                  >
                     {this.state.readOnly?"CHANGE":"CHECK"}
                  </button>
                </div>
              </div>
            </div>

            <div className="row" style={this.state.flagValidCar ? { display: 'block' } : { display: 'none' }}>
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      rows="3"
                      className="form-control"
                      name="textarea"
                      id="textarea"
                      placeholder={this.returnMsgCar(this.state.car_type, i18n.languages[0])}
                      disabled
                      readOnly={!this.state.readOnly}
                      style={this.formStyle}
                    ></textarea>
                  )}
                </Translation>
              </div>
            </div>

            <div
              className="row"
              style={
                this.state.flagValidCar && (this.state.car_pic === 2 || this.state.car_pic === 3)
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <div className="form-group col">
                <select
                  name="car_type"
                  id="car_type"
                  className="form-control"
                  style={this.formStyle}
                  readOnly={!this.state.readOnly}
                  onChange={({ target: { value } }) =>
                    this.setState({ car_type: value, car_pic: value === 'BENZ E CLASS' ? 2 : 3 })
                  }
                >
                  {this.state.carArr.map((val) => {
                    return <option value={val}>{val}</option>
                  })}
                </select>
              </div>
            </div>

            <h5>
              <Translation>{(t, { i18n }) => t('Personalinformation')}</Translation>
            </h5>
            <div className="row pt-1">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('firstname')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'firstname')}
                      readOnly={!this.state.readOnly}
                      value={this.state.firstname}
                      required
                    />
                  )}
                </Translation>
              </div>
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('lastname')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'lastname')}
                      readOnly={!this.state.readOnly}
                      value={this.state.lastname}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('phone')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'phone')}
                      readOnly={!this.state.readOnly}
                      value={this.state.phone}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('email')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'email')}
                      readOnly={!this.state.readOnly}
                      value={this.state.email}
                    />
                  )}
                </Translation>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <DatePicker
                      id="datepicker"
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholderText={t('BookingDate')}
                      style={{ width: '100% !important' }}
                      selected={this.state.Datetime}
                      minDate={addDays(new Date(), 2)}
                      onChange={this.onchangeDate}
                      readOnly={!this.state.readOnly}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="text"
                      id="booking_time"
                      name="booking_time"
                      className="form-control"
                      style={this.formStyle}
                      pattern="[0-9]{2}:[0-9]{2}"
                      placeholder={t('BookingTime')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'bookingTime')}
                      readOnly={!this.state.readOnly}
                      value={this.state.bookingTime}
                      required
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="number"
                      id="person"
                      name="person"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('person') + this.returnMsgCar(this.state.car_type, '', 'p')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'person')}
                      readOnly={!this.state.readOnly}
                      value={this.state.person}
                      required
                      min={1}
                      max={this.state.maxPersons}
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <Translation>
                  {(t, { i18n }) => (
                    <input
                      type="number"
                      id="luggage"
                      name="luggage"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('luggage') + this.returnMsgCar(this.state.car_type, '', 'l')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'luggage')}
                      value={this.state.luggage}
                      readOnly={!this.state.readOnly}
                      required
                      min={1}
                      max={this.state.maxLuggage}
                    />
                  )}
                </Translation>
              </div>
            </div>
          </div>
          <div id="step-2" className="step pt-4">
            <h5>
              <Translation>{(t, { i18n }) => t('Pickupinformation')}</Translation>
            </h5>
            <div className="row pt-2">
              <div className="form-group col">
                <select
                  name="pick_up_type"
                  id="pick_up_type"
                  className="form-control"
                  style={this.formStyle}
                  value={null}
                  onChange={({ target: { value } }) => this.setState({ pickupType: value })}
                  readOnly={!this.state.readOnly}
                  required
                >
                  <Translation>{(t, { i18n }) => <option value="">{t('option.default')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="1">{t('option.location')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="2">{t('option.airport')}</option>}</Translation>
                </select>
              </div>
            </div>
            <div
              id="pick_up-location"
              className="pick_up_group"
              style={{ display: this.state.pickupType == 1 ? 'initial' : 'none' }}
            >
              <div className="form-group">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      name="pick_up_address"
                      id="pick_up_address"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('address')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'pick_upAddress')}
                      value={this.state.pick_upAddress}
                      readOnly={!this.state.readOnly}
                      required={this.state.pickupType == 1 ? true : undefined}
                    ></textarea>
                  )}
                </Translation>
              </div>
              <div className="row">
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="pick_up_postcode"
                        name="pick_up_postcode"
                        className="form-control"
                        style={this.formStyle}
                        readOnly={!this.state.readOnly}
                        placeholder={t('point')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'pick_up_interest')}
                        value={this.state.pick_up_interest}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div
              id="pick_up-airport"
              className="pick_up_group"
              style={{ display: this.state.pickupType == 2 ? 'initial' : 'none' }}
            >
              <div className="row">
                <div className="form-group col dropdown">
                  <select
                    name="pick_up_airport"
                    id="pick_up_airport"
                    className="form-control"
                    style={this.formStyle}
                    value={this.state.pick_up_airport}
                    readOnly={!this.state.readOnly}
                    onChange={({ target: { value } }) => {
                      this.setState({ pick_up_airport: value })
                    }}
                    required={this.state.pickupType == 2 ? true : undefined}
                  >
                    <Translation>{(t, { i18n }) => <option value="1">{t('optionsAirport.su')}</option>}</Translation>
                    <Translation>{(t, { i18n }) => <option value="2">{t('optionsAirport.don')}</option>}</Translation>
                  </select>
                </div>
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="pick_up_flight_number"
                        name="pick_up_flight_number"
                        className="form-control"
                        style={this.formStyle}
                        readOnly={!this.state.readOnly}
                        placeholder={t('FlightNo')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'pick_upFlight')}
                        value={this.state.pick_upFlight}
                        required={this.state.pickupType == 2 ? true : undefined}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
          <div id="step-3" className="step pt-4">
            <h5>
              <Translation>{(t, { i18n }) => t('Dropoffinformation')}</Translation>
            </h5>
            <div className="row pt-2">
              <div className="form-group col">
                <select
                  name="drop_off_type"
                  id="drop_off_type"
                  className="form-control"
                  style={this.formStyle}
                  value={null}
                  onChange={({ target: { value } }) => this.setState({ dropoffType: value })}
                  readOnly={!this.state.readOnly}
                  required
                >
                  <Translation>{(t, { i18n }) => <option value="">{t('option.default')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="1">{t('option.location')}</option>}</Translation>
                  <Translation>{(t, { i18n }) => <option value="2">{t('option.airport')}</option>}</Translation>
                  ))
                </select>
              </div>
            </div>
            <div
              id="drop_off-location"
              className="drop_off_group"
              style={{ display: this.state.dropoffType == 1 ? 'initial' : 'none' }}
            >
              <div className="form-group">
                <Translation>
                  {(t, { i18n }) => (
                    <textarea
                      name="drop_off_address"
                      id="drop_off_address"
                      className="form-control"
                      style={this.formStyle}
                      placeholder={t('address')}
                      onChange={(e) => this.handleInputChange(e.target.value, 'drop_offAddress')}
                      value={this.state.drop_offAddress}
                      readOnly={!this.state.readOnly}
                      required={this.state.dropoffType == 1 ? true : undefined}
                    ></textarea>
                  )}
                </Translation>
              </div>
              <div className="row">
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="drop_off_postcode"
                        name="drop_off_postcode"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('point')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'drop_off_interest')}
                        value={this.state.drop_off_interest}
                        readOnly={!this.state.readOnly}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div
              id="drop_off-airport"
              className="drop_off_group"
              style={{ display: this.state.dropoffType == 2 ? 'initial' : 'none' }}
            >
              <div className="row">
                <div className="form-group col dropdown">
                  <select
                    name="drop_off_airport"
                    id="drop_off_airport"
                    className="form-control"
                    style={this.formStyle}
                    value={this.state.drop_off_airport}
                    onChange={({ target: { value } }) => {
                      this.setState({ drop_off_airport: value })
                    }}
                    readOnly={!this.state.readOnly}
                  >
                    <Translation>{(t, { i18n }) => <option value="1">{t('optionsAirport.su')}</option>}</Translation>
                    <Translation>{(t, { i18n }) => <option value="2">{t('optionsAirport.don')}</option>}</Translation>
                  </select>
                </div>
                <div className="form-group col">
                  <Translation>
                    {(t, { i18n }) => (
                      <input
                        type="text"
                        id="drop_off_flight_number"
                        name="drop_off_flight_number"
                        className="form-control"
                        style={this.formStyle}
                        placeholder={t('FlightNo')}
                        onChange={(e) => this.handleInputChange(e.target.value, 'drop_offFlight')}
                        value={this.state.drop_offFlight}
                        readOnly={!this.state.readOnly}
                        required={this.state.dropoffType == 2 ? true : undefined}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
          <div id="step-4" className="step  pt-3">
            <h5>
              <Translation>{(t, { i18n }) => t('SpecialNote')}</Translation>
            </h5>
            <div className="form-group">
              <Translation>
                {(t, { i18n }) => (
                  <textarea
                    name="special_Note"
                    id="special_Note"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={t('SpecialNote')}
                    onChange={(e) => this.handleInputChange(e.target.value, 'note')}
                    value={this.state.note}
                    readOnly={!this.state.readOnly}
                  ></textarea>
                )}
              </Translation>
            </div>
            <br />

            <div className="text-center  pt-1 pb-3">
              <button type="button" className="btn-grey-border">
                <Translation>{(t, { i18n }) => t('Back')}</Translation>
              </button>

              <button type="submit" id="btn-submit" className="btn-red-gradient" style={{cursor: this.state.disabled || !this.state.readOnly?"not-allowed":"pointer"}} disabled={this.state.disabled || !this.state.readOnly}>
                <Translation>{(t, { i18n }) => t('Booking')}</Translation>
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup }) => {
  return { coupon, popup }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
  submitBookingMonday,
  submitToCore,
  GetCodeValid,
})(MainForm)