import React from 'react'
import PopupLayout from './layoutModal'

function PopupError(props) {
  return (
    <PopupLayout display={props.display}>
      <div className="modal-body text-center">
        <h1 className="display-1 text-danger">
          <i className="far fa-dizzy"></i>
        </h1>
        <div className="pb-4">{props.msg}</div>
        <div className="row">
          <div className="col"></div>
          <div className="col-8">
            {' '}
            <button
              className="btn btn-block btn-primary"
              onClick={() => {
                props.closePopup()
              }}
            >
              ลองอีกครั้ง
            </button>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </PopupLayout>
  )
}

export default PopupError
